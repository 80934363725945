import classNames from 'classnames'
import Link from 'next/link'

type Props = {
  className?: string
  title: string
  href: string
}
const CTAButton = (props: Props) => {
  const { className, title, href } = props
  return (
    <Link
      href={href}
      className={classNames(
        'relative z-20 inline-block cursor-pointer rounded-[30px] bg-black/10',
        'bg-primary px-4 py-[14px] text-xs text-white transition-all',
        'hover:rotate-[-4deg] hover:drop-shadow-lg lg:px-5 lg:py-[18px]',
        'lg:text-base lg:leading-none xl:text-xl',
        className,
      )}
    >
      {title}
    </Link>
  )
}

export default CTAButton
