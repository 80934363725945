import { SVGProps } from 'react'

const GlobeIconWithCircle = (props: SVGProps<SVGSVGElement>) => {
  return (
    <span data-nosnippet>
      <svg width="135" height="120" viewBox="0 0 135 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_4312_201899)">
          <path
            d="M115.429 43.5243C113.583 43.0712 111.473 42.7009 109.142 42.4132M110.201 39.3925C115.501 39.6618 120.475 40.3454 125.681 41.948C140.046 46.3701 126.779 56.4704 118.779 61.2198C96.6243 74.3724 78.0435 77.4923 50.5625 81.1124C40.9388 82.3802 23.431 81.67 14.2294 80.0038C10.7141 79.3672 0.280283 75.8636 3.78191 69.1353C7.24834 63.9042 13.6574 59.4125 21.7792 55.6666"
            stroke="#A8FF8F"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <path
            d="M22.2773 60.4602C22.2773 66.3697 23.4413 72.2213 25.7028 77.681C27.9642 83.1406 31.2789 88.1014 35.4575 92.28C39.6362 96.4586 44.5969 99.7733 50.0566 102.035C55.5162 104.296 61.3679 105.46 67.2773 105.46C73.1868 105.46 79.0384 104.296 84.4981 102.035C89.9577 99.7733 94.9185 96.4586 99.0971 92.28C103.276 88.1014 106.59 83.1406 108.852 77.681C111.113 72.2213 112.277 66.3697 112.277 60.4602C112.277 48.5255 107.536 37.0795 99.0971 28.6404C90.658 20.2013 79.2121 15.4602 67.2773 15.4602C55.3426 15.4602 43.8967 20.2013 35.4575 28.6404C27.0184 37.0795 22.2773 48.5255 22.2773 60.4602Z"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.2852 45.4602H109.285"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M25.2852 75.4602H109.285"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M64.7757 15.4602C56.3524 28.9583 51.8867 44.5495 51.8867 60.4602C51.8867 76.3709 56.3524 91.9621 64.7757 105.46"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M69.7773 15.4602C78.2006 28.9583 82.6663 44.5495 82.6663 60.4602C82.6663 76.3709 78.2006 91.9621 69.7773 105.46"
            stroke="black"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M3.77992 69.1353C0.278291 75.8635 10.7121 79.3672 14.2274 80.0037C23.429 81.67 40.9368 82.3801 50.5605 81.1124C78.0415 77.4922 96.6223 74.3723 118.777 61.2197C126.777 56.4704 140.044 46.37 125.679 41.9479C120.473 40.3453 115.499 39.6617 110.199 39.3924"
            stroke="#A8FF8F"
            strokeWidth="3"
            strokeLinecap="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_4312_201899">
            <rect width="135" height="120" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </span>
  )
}

export default GlobeIconWithCircle
