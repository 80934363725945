import Metadata from 'shared/components/Metadata'
import dynamic from 'next/dynamic'
import Header from 'shared/components/layout/Header'
import TopFold from 'views/homepage/components/TopFold'
import useAuth from 'hooks/authentication/useAuth'
import { generateHomepageJsonLd } from 'views/homepage/utils/generateHomepageJsonLd'

const NewFeature = dynamic(() => import('views/homepage/components/MobileStorefront'), { ssr: false })
const DiscoverArtYouLove = dynamic(() => import('views/homepage/components/DiscoverArtYouLove'), { ssr: false })
const EngageInCommunity = dynamic(() => import('views/homepage/components/EngageInCommunity'), { ssr: false })
const AdvisorSection = dynamic(() => import('views/homepage/components/AdvisorSection'), { ssr: false })
const UserExplorer = dynamic(() => import('views/homepage/components/UserExplorer'), { ssr: false })
const Testimonials = dynamic(() => import('views/homepage/components/Testimonials'), { ssr: false })
const InvestorSection = dynamic(() => import('views/homepage/components/InvestorSection'), { ssr: false })
const NewsBanner = dynamic(() => import('views/homepage/components/NewsBanner'), { ssr: false })
const FooterSection = dynamic(() => import('shared/components/layout/FooterSection'), { ssr: false })

const preloadImageUrls = [
  '/images/homepage/hero-section/hero-banner-mobile.webp',
  '/images/homepage/hero-section/hero-banner-desktop.webp',
  '/images/homepage/hero-section/iphone.webp',
]

const HomePage = () => {
  const { isLoggedIn, isFetching: isAuthFetching } = useAuth()
  return (
    <>
      <Metadata
        url="/"
        jsonLd={generateHomepageJsonLd()}
        jsonLdKey={'homepageJsonLdKey'}
        preloadImageUrls={preloadImageUrls}
      />
      <div className="snap-y snap-proximity">
        <Header />
        <div>
          <TopFold isLoggedIn={!!isLoggedIn} isAuthFetching={isAuthFetching} />
          <UserExplorer />
          <NewFeature />
          <DiscoverArtYouLove />
          <EngageInCommunity />
          <Testimonials />
          <NewsBanner />
          <InvestorSection />
          <AdvisorSection />
          <div className="mx-10 mb-10 rounded-[8px] border border-black/10 bg-[#F9F9F9] px-[22px] py-[21px] lg:rounded-[30px]  lg:py-[30px]">
            <p className="text-left text-sm md:text-center lg:text-[20px] lg:leading-[25px]">
              Interested in joining our&nbsp;<strong>globally distributed</strong>,&nbsp;<strong>remote-first</strong>
              &nbsp;team of<strong>&nbsp;disruptive</strong>&nbsp;
              <br className="hidden md:block" />
              <strong>changemakers</strong>? Send us an email&nbsp;
              <a className="text-primary" href="mailto:jobs@cohart.com">
                jobs@cohart.com
              </a>
            </p>
          </div>
        </div>
        <FooterSection />
      </div>
    </>
  )
}

export default HomePage
